<template>
  <div>
    <div v-show="this.$route.meta.showRole">
      <div>
        <el-select
          class="condition_input"
          v-model="customerId"
          placeholder="请选择客户名称"
        >
          <el-option value="" label="全部"></el-option>
          <el-option
            v-for="item in customer"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          clearable
          filterable
          style="width: 160px;margin-left: 10px"
          v-model="assetType"
          placeholder="请选择资产型号"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button class="condition_button button_default" @click="query"
          >查询</el-button
        >
        <el-button
          class="condition_button import"
          icon="el-icon-download"
          @click="add"
          >添加</el-button
        >
        <el-button
          class="condition_button import"
          icon="el-icon-delete"
          @click="delImg(null)"
          >删除</el-button
        >
        <!--            <el-button class="condition_button import" icon="el-icon-download">导入</el-button>-->
        <!--            <el-button class="condition_button import" icon="el-icon-upload2">导出</el-button>-->
      </div>
      <div class="table">
        <el-table
          stripe
          :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column prop="customerName" label="客户名称" width="180">
          </el-table-column>
          <el-table-column prop="deviceFeature" label="产品型号" width="180">
          </el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope"
              ><img
                v-if="scope.row.imageUrl"
                style="width: 50px;height: 50px"
                :src="scope.row.imageUrl"
                :alt="scope.row.imageId"
            /></template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                class="edit el-icon-edit"
                type="text"
                size="small"
                @click="edit(scope.row)"
                >编辑</el-button
              >
              <i class="app-operation-line" />
              <el-button
                type="text"
                size="small"
                style="color: #F56C6C;font-size: 14px;"
                @click="delImg(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="app-page-container">
          <span @click="first">首页</span>
          <el-pagination
            @current-change="handleCurrentChange"
            layout="pager"
            :hide-on-single-page="false"
            :page-size="12"
            :current-page.sync="currentPage"
            :total="recordCount"
          >
          </el-pagination>
          <span @click="last">尾页</span>
        </div>
      </div>
    </div>
    <router-view @confirm="query(false)"></router-view>
  </div>
</template>

<script>
import {
  imgQuery,
  getAssetType,
  deleteImg,
  clientName
} from "../../../api/AssetManagement";
export default {
  name: "index",
  data() {
    return {
      customerId: "",
      assetType: "",
      options: [],
      recordCount: 0,
      currentPage: 1,
      tableData: [],
      pages: 1,
      customer: [],
      imgs: []
    };
  },
  created() {
    this.query(false);
    this.getAssetTypes();
    this.getClient();
  },
  methods: {
    //添加、编辑
    edit(data) {
      this.$router.push({
        path: "/dashboard/client/imgManage/add",
        query: data
      });
    },
    add() {
      this.$router.push("/dashboard/client/imgManage/add");
    },
    //删除
    delImg(datas) {
      let data = { ids: [] };
      if (datas) {
        data.ids = [datas.imageId];
      } else {
        for (let i = 0; i < this.imgs.length; i++) {
          data.ids.push(this.imgs[i].imageId);
        }
      }
      deleteImg(data).then(res => {
        console.log(res);
        this.query(false);
      });
    },
    //获取资产类型
    getAssetTypes() {
      getAssetType({}).then(res => {
        this.options = res.data.data || [];
      });
    },
    //拉去客户名称
    getClient() {
      clientName({}).then(res => {
        this.customer = res.data.data || [];
      });
    },
    //查询
    query(type) {
      // let da =  this.$route.meta;
      if (!type) {
        this.pages = 1;
      }
      let data = {
        curPage: this.pages,
        limit: 12,
        featureId: this.assetType,
        customerId: this.customerId
      };
      imgQuery(data).then(res => {
        this.tableData = res.data.records;
        this.recordCount = res.data.total;
        // this.pages = res.data.pages;
      });
    },
    //表格多选
    handleSelectionChange(val) {
      this.imgs = val;
    },
    //分页
    handleCurrentChange(val) {
      this.pages = val;
      this.query(true);
    },
    //首页
    first() {
      this.tableData = [];
      this.currentPage = 1;
      this.pages = 1;
      this.query(true);
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.query(true);
    }
  }
};
</script>

<style scoped>
.edit {
  font-size: 14px;
  color: #6e7790;
}
.edit:hover {
  color: #0cc;
}
.el-icon-edit:hover {
  color: #0cc;
}
.import {
  color: #6e7790;
  background: #f1f1f4;
  border-color: #c5c9d3;
}
.import:hover {
  background: #6e7790;
  border-color: #6e7790;
  color: #fff;
}
.import:focus {
  background: #6e7790;
  border-color: #6e7790;
  color: #fff;
}
.button_default {
  color: #0cc;
  border-color: #99ebeb;
}
.button_default:hover {
  color: #fff;
  border-color: #b3f0f0;
  background-color: #0cc;
}
.button_default:focus {
  color: #fff;
  border-color: #b3f0f0;
  background-color: #0cc;
}
/*表格样式*/
.table {
  margin-top: 20px;
}
.condition_input {
  width: 240px;
}
.condition_button {
  margin-left: 10px;
}
/*分页样式*/
.app-page-container {
  margin-top: 20px;
}
.app-page-container .el-pagination {
  display: inline-block;
}
.app-page-container span {
  cursor: pointer;
  color: #616161;
  background: #fff;
  border: 1px solid #e8eaf0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  vertical-align: top;
  border-radius: 16px;
  font-size: 12px;
  width: 56px;
}
.app-page-container span:hover {
  background: #00cccc;
  color: #fff;
  border-color: #00cccc;
}
</style>
